/* eslint-disable no-case-declarations */
import { Image } from '../../server'
import { flatData } from '../flat/flats_data'
import { Flat_Wrapper, Flat_Wrapper_Levels } from '../flat/flat_class_Wrapped'
import { checkFlatAvailability, getAvailableProjects } from '../flat/flat_gen_Functions'

/* In progress */
export enum Justina_Wrapper {
    HeadInfo = 0,
    Descrb,
    TextSep,
    SthElse,
    Carousel,
    BottomSthElse,
    FlatList,
    BottomSep,
}

export function getVillaJustinaInfoWrapped(data: Justina_Wrapper) {
    switch (data) {
        case Justina_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Villa Justina'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wohnungen zum verlieben',
                            to: '/wohnungen/justina',
                            slash: false,
                        },
                    ],
                    subTitle: ['Modern und hochwertig <br/>Wohnen in der Villa Justina'],
                },
                image: {
                    name: 'img_f_justina',
                    folder: ['imgs'],
                    alt: 'Villa Justina',
                },
                content: {
                    banner: ['ab 96m²', 'bis 109m²'],
                    text: [
                        `Willkommen in der Villa Justina. ` +
                            `Die Mietwohnungen im Neubau Villa Justina bieten durchdacht gestalteten, hochwertigen Wohnraum, der Funktionalität und Ästhetik harmonisch vereint. ` +
                            `Mit einem modernen Schnitt und qualitativ hochwertiger Ausstattung, inklusive effizienter Be- und Entlüftungsanlagen, gewährleisten diese Einheiten ein behagliches und gesundes Wohnklima. `,
                    ],
                },
            }
        case Justina_Wrapper.Descrb:
            return {
                image: {
                    name: 'img_og',
                    folder: ['imgs'],
                    alt: 'Konzeptbild',
                },
                content: [
                    'Wohnen in der Villa Justina',
                    '- Ein exklusives Wohngefühl',
                    `Im stilvollen Treppenhaus, das von einem bequemen Aufzug ergänzt wird, erlebt man die gelungene Verbindung von Design und Praktikabilität. ` +
                        `In den sechs individuell gestalteten Wohnungen sorgen hochwertiges Echtholzparkett und erstklassige Badausstattungen für eine einladende Atmosphäre, die höchsten Wohnansprüchen gerecht wird. ` +
                        `Helle Räume und eine moderne Luft-Wasser-Wärmepumpe stehen für die perfekte Kombination von ansprechendem Wohnambiente und energieeffizienter Ausstattung in der Villa Justina.`,
                ],
            }
        case Justina_Wrapper.TextSep:
            return {
                content: [
                    'Die Villa Leuna, positioniert in der malerischen Villenlage von Leuna, vereint erhabenes Wohnen mit direkter Naturanbindung. Nur ein kurzer Weg trennt Sie von der Saale und ihren einladenden Spazierwegen, die zu erholsamen Auszeiten am Flussufer verführen.',
                ],
                link: { text: 'Erkunde die Lage', link: '', scrollTo: false },
            }

        case Justina_Wrapper.Carousel:
            const _images = []
            const list = 3

            for (let i = 1; i < list + 1; i++) {
                _images.push({
                    name: `img_${i}`,
                    folder: ['imgs', 'justina'],
                    alt: `Villa Justina img_${i}`,
                })
            }
            _images.push({
                name: `img_eg`,
                folder: ['imgs'],
                alt: `Villa Leuna EG Flur`,
            })
            _images.push({
                name: `img_og`,
                folder: ['imgs'],
                alt: `Villa Leuna OG Flur`,
            })
            _images.push({
                name: `img_dg`,
                folder: ['imgs'],
                alt: `Villa Leuna DG Flur`,
            })

            return {
                head: {
                    title: ['Bilder?'],
                },
                images: _images,
            }

        case Justina_Wrapper.BottomSthElse:
            const avaibableFlatsJohanna = getAvailableProjects().includes('johanna')

            let links: { text: string; link: string; scrollTo: boolean }[] = []
            let image: { name: string; folder: string[]; alt: string; project?: string } = { name: '', folder: [''], alt: '' }
            if (avaibableFlatsJohanna) {
                links.push({
                    text: 'Erkunde die Villa Johanna',
                    link: '/wohnungen/johanna',
                    scrollTo: false,
                })
                image.name = 'img_f_johanna'
                image.folder = ['imgs']
                image.alt = 'Villa Johanna von Vorne'
            } else {
                links.push({ text: 'Erkunde den Alten Fährhof', link: 'https://alter-faehrhof.de', scrollTo: false })
                image.name = 'main_5'
                image.folder = ['imgs', 'root']
                image.project = 'faehrhof'
                image.alt = 'Alter Fährhof von Vorne'
            }
            return {
                title: ['Noch nicht das Richtige gefunden?'],
                links: links,
                image: image,
            }
        case Justina_Wrapper.FlatList:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_EGFloor: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_OGFloor: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_DGFloor: any[] = []
            // eslint-disable-next-line no-lone-blocks
            {
                for (let k = 6; k < 12; k++) {
                    const data: Flat_Wrapper = flatData[k]
                    const newFlat: {
                        number: number
                        price: { main: number; partial: number }
                        size: number
                        available: boolean
                        rooms: { living: number; baths: number; sleep: number }
                        levels: { wLevels: Flat_Wrapper_Levels[]; nMain: number }
                        entrance: string
                        outSideExtra: string
                        blueprint: Image[]
                    } = {
                        number: data.flatNumber,
                        price: data.price,
                        size: data.size,
                        available: checkFlatAvailability(data.flatNumber),
                        rooms: data.rooms,
                        levels: data.levels,
                        entrance: data.entrance,
                        outSideExtra: data.outSideExtra,
                        blueprint: data.blueprint,
                    }

                    if (newFlat.levels.wLevels[0] === Flat_Wrapper_Levels.Erdgeschoss) {
                        _flats_EGFloor.push(newFlat)
                    } else if (newFlat.levels.wLevels[0] === Flat_Wrapper_Levels.Obergeschoss) {
                        _flats_OGFloor.push(newFlat)
                    } else {
                        _flats_DGFloor.push(newFlat)
                    }
                }
            }

            return {
                content: [
                    'Wohneinheiten',
                    'in der Villa Justina',
                    'Sie erwarten sechs charmante Mietwohnungen, die sich auf zwei Wohnungen pro Etage verteilen. ',
                    `Über das zentrale Treppenhaus erreichen Sie bequem und ebenerdig die einzelnen Wohnungen. 
                    Entdecken Sie die Wohnungen und finden Sie Ihren ganz persönlichen Rückzugsort im Wohnkomplex der Villa Leuna.`,
                ],
                image: {
                    name: 'img_f_justina',
                    folder: ['imgs'],
                    alt: 'Villa Justina von Vorne',
                },
                mainLink: '/wohnungen/justina',
                type: 'justina',
                flats: [_flats_EGFloor, _flats_OGFloor, _flats_DGFloor],
                unavailable: {
                    projectNone: {
                        content: [
                            `Es sind aktuell keine Mietwohnungen verfügbar. `,
                            'Erkunden Sie auch unser neustes Projekt! Wir präsentieren Ihnen stolz 14 großzügige und hochwertige Wohnungen im Alten Fährhof in Bad Dürrenberg, direkt an der Saale.',
                        ],
                        link: {
                            text: 'Zum Alten Fährhof',
                            link: 'http://alter-faehrhof.de',
                            scrollTo: false,
                        },
                    },
                    projectOther: {
                        content: [`Es sind aktuell keine Mietwohnungen in der Villa Justina verfügbar.`, 'Erkunden Sie auch die Villa Johanna nach freien Wohnungen.'],
                        link: {
                            text: 'Zur Villa Johanna',
                            link: '/wohnungen/johanna',
                            scrollTo: false,
                        },
                    },
                },
            }
    }
}
