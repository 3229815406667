/* eslint-disable no-undef  */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { initGA } from './ga-utils'
import "./cookieconsent.css"
const domain = 'villa-leuna.de'

export const checkIfGAIsInitialized = function (cookie) {
    if (!get_cookie('cc_cookie')) return

    if (cookie.categories.includes('google-analytics')) {
        if (get_cookie('_ga') && get_cookie('_ga_' + String(process.env.REACT_APP_GOOGLE_ANALYTICS_ID).substring(2))) return

        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
        }
    } else if (get_cookie('_ga') || get_cookie('_ga_' + String(process.env.REACT_APP_GOOGLE_ANALYTICS_ID).substring(2))) {
        delete_cookie('_ga', '/', domain)
        delete_cookie('_ga_' + String(process.env.REACT_APP_GOOGLE_ANALYTICS_ID).substring(2), '/', domain)
        delete_cookie('_gat', '/', domain)
        delete_cookie('_gid', '/', domain)
        delete_cookie('AMP_TOKEN', '/', domain)
    }
}

const delete_cookie = function (name, path, domain) {
    if (get_cookie(name)) {
        document.cookie = name + '=' + (path ? ';path=' + path : ';path=/') + (domain ? ';domain=' + domain : ';domain=villa-leuna.de') + ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
    }
}

const get_cookie = function (name) {
    var d = [],
        e = document.cookie.split(';')
    name = RegExp('^\\s*' + name + '=\\s*(.*?)\\s*$')
    for (var b = 0; b < e.length; b++) {
        var f = e[b].match(name)
        f && d.push(f[1])
    }
    return d.length === 0 ? false : d[0]
}
/**
 * @type {UserConfig}
 */
const pluginConfig = {
    current_lang: 'de',
    autoclear_cookies: true,
    page_scripts: true,

    onFirstAction: function (user_preferences, cookie) {
        var is_root = window.location.href.split('/')
        if (is_root[is_root.length - 2].includes('.de')) {
            if (user_preferences.accept_type !== 'necessary') {
                setTimeout(() => {
                    window.location.reload()
                }, 100)
            }
        }
    },

    onAccept: function (cookie) {
        var is_root = window.location.href.split('/')

        if (is_root[is_root.length - 2].includes('.de')) {
            setTimeout(() => {
                checkIfGAIsInitialized(cookie)
            }, 100)
        }
    },

    onChange: function (cookie, changed_preferences) {
        var is_root = window.location.href.split('/')
        if (is_root[is_root.length - 2].includes('.de')) {
            setTimeout(() => {
                window.location.reload()
            }, 100)
        }
    },

    gui_options: {
        consent_modal: {
            layout: 'bar',
            position: 'bottom center',
            transition: 'slide',
            swap_buttons: false,
        },
        settings_modal: {
            layout: 'box',
            transition: 'slide',
        },
    },

    languages: {
        de: {
            consent_modal: {
                title: 'Wir verwenden Cookies!',
                description: `Guten Tag, diese Webseite verwendet für eine verbesserte Interaktion externe Serverdaten, Cookies und externe Dienstleister. 
                    Das Letztere wird erst durch Ihre Zustimmung ausgeführt. <button type="button" data-cc="c-settings" class="cc-link">Lass mich wählen</button>`,
                primary_btn: {
                    text: 'Alle aktivieren',
                    role: 'accept_all',
                },
                secondary_btn: {
                    text: 'Nicht aktivieren',
                    role: 'accept_necessary',
                },
            },
            settings_modal: {
                title: 'Benutzer Einstellungen',
                save_settings_btn: 'Auswahl Speichern',
                accept_all_btn: 'Alle aktivieren',
                reject_all_btn: 'Nicht aktivieren',
                close_btn_label: 'Schließen',
                cookie_table_headers: [{ col1: 'Domain' }, { col2: 'Name' }, { col3: 'Ablauf' }, { col4: 'Beschreibung' }],
                blocks: [
                    {
                        title: 'Datennutzung und /-zugriffe 📢',
                        description: `Diese Webseite verwendet für eine nutzerfreundliche und stabile Interaktion unter anderem Cookies. 
                            Auch werden auf externe Server zugegriffen, um Daten schneller und mit mehr Flexibilität Ihnen präsentieren zu können. 
                            Des Weiteren werden externe Dienstleister verwendet, um die Interkation und Nutzung Ihnen so angenehm wie möglich zu gestalten. 
                            Sie können im Folgenden durch das Anklicken der Knöpfe, den Vorgaben zu und -wiedersprechen. 
                            Für mehr Details bezogen auf Cookies und der Verwendung sensibler Nutzerdaten, 
                            verweisen wir auf die vollständige <a href="/impressum" class="cc-link">Datenschutzerklärung</a>.`,
                    },
                    {
                        title: 'Notwendige Cookies',
                        description: `Diese Cookies werden für Nutzung der Webseite benötigt. Ohne diese wäre eine reibungslose Interaktion nicht möglich. 
                            Unter anderem werden diese Einstellungen lokal auf Ihrem Endgerät gespeichert, um Ihnen eine Wiederholte Auswahl zu vermeiden.`,
                        toggle: {
                            value: 'necessary-cookies',
                            enabled: true,
                            readonly: true,
                        },
                        cookie_table: [
                            {
                                col1: 'villa-leuna.de',
                                col2: 'cc_cookie',
                                col3: '6 Monate',
                                col4: 'Wird für die Speicherung Ihrer Cookie Einstellungen verwendet.',
                                is_regex: true,
                            },
                        ],
                    },
                    {
                        title: 'Notwenige Externe Ressourcenzugriffe',
                        description: 'Diese externen Zugriffe sind unabdingbar um Ihnen eine performante Webseite, egal wo Sie sich auch befinden, präsentieren zu können. ',
                        toggle: {
                            value: 'necassary-external',
                            enabled: true,
                            readonly: true,
                        },
                        cookie_table: [
                            {
                                col1: 'cloudinary.com',
                                col2: 'Cloudinary',
                                col3: '/',
                                col4: 'Ermöglicht die für Sie optmierte Darstellung von Bildern auf der Webseite.',
                                is_regex: true,
                            },
                            {
                                col1: 'cloudflare.com',
                                col2: 'Cloudflare',
                                col3: '/',
                                col4: 'Webhoster, der die Webseite bereitstellt.',
                            },
                        ],
                    },

                    {
                        title: 'Google Analytics',
                        description: `Die Verwendung von Google Analytics ermöglicht uns die Analyse der Nutzung unserer Webseite. 
                            Diese Daten werden anonymisiert gespeichert und dienen uns zur Verbesserung der Webseite.`,
                        toggle: {
                            value: 'google-analytics',
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: 'villa-leuna.de',
                                col2: '_ga',
                                col3: '1 Jahre',
                                col4: 'Unterscheidet einzelne Benutzer auf Ihrer Domain.',
                                is_regex: true,
                            },
                            {
                                col1: 'villa-leuna.de',
                                col2: '_gid',
                                col3: '1 Jahr',
                                col4: 'Unterscheidet einzelne Benutzer auf Ihrer Domain.',
                                is_regex: true,
                            },
                            {
                                col1: 'villa-leuna.de',
                                col2: '_gat',
                                col3: '1 Jahr',
                                col4: 'Begrenzt die Benutzeranfragen, um die Website-Leistung aufrechtzuerhalten.',
                                is_regex: true,
                            },
                            {
                                col1: 'villa-leuna.de',
                                col2: 'AMP_TOKEN',
                                col3: '1 Jahr',
                                col4: 'Weist jedem Benutzer auf Ihrer Domain eine eindeutige ID zu.',
                                is_regex: true,
                            },
                            {
                                col1: 'villa-leuna.de',
                                col2: 'gac<property-id>',
                                col3: '1 Jahr',
                                col4: 'Ermöglicht die Zusammenarbeit zwischen Google Analytics und Ads durch eine eindeutige ID.',
                                is_regex: true,
                            },
                        ],
                    },
                    {
                        title: 'Externe Dienstleister',
                        description: 'Diese Externen Ressourcen ermöglichen Ihnen externe Funktionalitäten, wie u.a. die eingebetteten Karten des Dienstleisters MapBox, zu verwenden.',
                        toggle: {
                            value: 'mapbox',
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: 'https://api.mapbox.com/v4/',
                                col2: 'Mapbox',
                                col3: '/',
                                col4: 'Zugriff auf die Karten von MapBox.',
                                is_regex: true,
                            },
                            {
                                col1: 'Lokaler Speicher',
                                col2: 'Mapbox Event Data',
                                col3: '/',
                                col4: 'Initiiert die Karte am Alten Fährhof von MapBox.',
                                is_regex: true,
                            },
                        ],
                    },
                    {
                        title: 'Mehr Information',
                        description: `Für weitere Fragen bezogen auf die Verwendung von externen Ressourcen und Cookies, 
                            bitte wir Sie uns <a class="cc-link" href="mailto:kontakt@villa-leuna.de?subject=Villa-Leuna%20-%20Cookie%20Einstellungen%20Frage">zu kontaktieren</a>.`,
                    },
                ],
            },
        },
    },
}

export default pluginConfig
