import { Image } from '../../server'

export enum Flat_Wrapper_Levels {
    Erdgeschoss = 'Erdgeschoss',
    Obergeschoss = 'Obergeschoss',
    Dachgeschoss = 'Dachgeschoss',
}

export enum Flat_Wrapper_Outside_Perk {
    Terrace = 'Große Terrasse',
    Balcony = 'Großer Balkon',
    None = '',
}

export enum Flat_Wrapper_Side {
    Street = 'Straße',
    Both = '',
}

export enum Flat_Wrapper_Building {
    Johanna = 'johanna',
    Justina = 'justina',
}

export enum Flat_Wrapper_Entrance_Side {
    Street = 'Straße',
}

export enum Flat_Page_Component_Wrapper {
    HeadInfo = 0,
    Details,
    Carousel,
    SthElse,
    Blueprint,
    Attributes,
}

export enum Flat_Wrapper_Heating {
    Central = 'Zentrale Luft-Wasser-Wärmepumpe',
    Floor = 'Effiziente Fußbodenheizung',
}

export enum Flat_Wrapper_Venting {
    ERoll = 'Elektrische Rollladen',
    AirMove = 'Be- und Entlüftungsanlage mit Wärmerückgewinnung',
}

export enum Flat_Wrapper_Baths {
    MainB = 'Hauptbadezimmer',
}

export enum Flat_Wrapper_Garage {
    Garage = 'Garage mit fernsteuerbarem Sektionaltor',
    Power = 'Stromanschluss in der Garage',
}

export enum Flat_Wrapper_Bike {
    Bike = 'Fahrradraum',
}

export class Flat_Page_Wrapper {
    constructor(
        public flat: number,
        public segment: Flat_Wrapper_Building,
        public component: Flat_Page_Component_Wrapper
    ) {}
}

export class Flat_Wrapper {
    blueprint: Image[]
    images: { count: number; value: Image[]; head: Image }
    constructor(
        public flatNumber: number,
        public price: { main: number; partial: number },
        public availability: boolean,
        public size: number,
        public levels: { wLevels: Flat_Wrapper_Levels[]; nMain: number },
        public rooms: { living: number; baths: number; sleep: number },
        public outSideExtra: Flat_Wrapper_Outside_Perk,
        public side: Flat_Wrapper_Side,
        public segment: Flat_Wrapper_Building,
        public entrance: Flat_Wrapper_Entrance_Side,
        public image: { count: number; description: Array<string> },
        public headImage: { name: string; folder: string[] },
        public technical: {
            heating: Flat_Wrapper_Heating[]
            venting: Flat_Wrapper_Venting[]
            garage: Flat_Wrapper_Garage[]
            bike: Flat_Wrapper_Bike[]
        },
        public cDescrView: string[],
        public fHeadDescr: string,
        public colorScheme?: { r: number; g: number; b: number }
    ) {
        this.blueprint = this.getBlueprintImages()
        this.images = {
            count: image.count,
            value: this.getImages(image.count, image.description),
            head: this.getHImage(headImage.folder, headImage.name),
        }
    }

    getBlueprintImages(): Image[] {
        let _images: Image[] = []

        _images = [
            {
                name: 'blueprint',
                description: 'Grundriss',
                folder: ['imgs', 'flats', 'we_' + this.flatNumber],
                alt: 'Grundriss Wohnung ' + this.flatNumber,
            },
        ]
        return _images
    }

    getImages(imageCount: number, descriptionArray: Array<string>): Image[] {
        const _images = []

        for (let i = 1; i < imageCount + 1; i++) {
            let description
            try {
                description = descriptionArray[i - 1]
            } catch (e) {
                /// TODO: ERROR HANDLING
            }
            _images.push({
                name: `img_${i}`,
                description: description,
                folder: ['imgs', 'flats', `we_${this.flatNumber}`],
                alt: `Bild Wohnung ${this.flatNumber}`,
            })
        }
        return _images
    }

    getHImage(folder: string[], name: string): Image {
        const image = {
            name: name,
            description: `flat_head_${this.flatNumber}`,
            folder: folder,
            alt: `Bildkopf Wohnung ${this.flatNumber}`,
        }
        return image
    }

    getNumberasString(a: number, uppercase: boolean): string {
        switch (a) {
            case 1:
                return uppercase ? 'Ein' : 'ein'
            case 2:
                return uppercase ? 'Zwei' : 'zwei'
            case 3:
                return uppercase ? 'Drei' : 'drei'
            case 4:
                return uppercase ? 'Vier' : 'vier'
            case 5:
                return uppercase ? 'Fünf' : 'fünf'
            case 6:
                return uppercase ? 'Sechs' : 'sechs'
            default:
                return a.toString()
        }
    }
}
