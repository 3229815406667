import packageInfo from '../../package.json'

export const cloudinaryImgLink = 'https://res.cloudinary.com/dqav4uxkf/image/upload'

export const cloudinaryVidLink = 'https://res.cloudinary.com/dqav4uxkf/video/upload'

export enum Project {
    villaLeuna = 'villaLeuna',
    villaArbor = 'villaArbor',
    alterfaehrhof = 'faehrhof',
}

export class CloudinaryOptions {
    public vcAuto?: boolean
    public fAuto?: boolean
    public qAuto?: boolean
    public qCustom?: number
    public width?: number
    public height?: number
    public small?: boolean
    public large?: boolean
    public cropAspect?: number

    constructor(options: { vcAuto?: boolean; fAuto?: boolean; qAuto?: boolean; qCustom?: number; width?: number; height?: number; small?: boolean; large?: boolean; cropAspect?: number }) {
        this.vcAuto = options.vcAuto
        this.fAuto = options.fAuto
        this.qAuto = options.qAuto
        this.qCustom = options.qCustom
        this.width = options.width
        this.height = options.height
        this.small = options.small
        this.large = options.large
        this.cropAspect = options.cropAspect
    }

    optionReturn(filetype: { video?: boolean; image?: boolean }): string {
        let options = ''
        if (filetype.video) {
            if (this.vcAuto) {
                options += '/vc_auto'
            }
        }
        if (this.fAuto) {
            options += '/f_auto'
        }
        if (this.qAuto) {
            options += '/q_auto:best'
        }
        if (this.qCustom) {
            options += '/q_' + this.qCustom
        }
        if (this.cropAspect) {
            options += '/ar_' + this.cropAspect + ',c_crop,w_' + this.width
        } else {
            if (this.width) {
                options += '/w_' + this.width
            }
            if (this.height) {
                options += '/h_' + this.height
            }
            if (this.width || this.height) {
                options += '/c_scale'
            }
        }
        if (this.large) {
            options += '/w_1500/h_1000/c_scale'
        }
        if (this.small) {
            options += '/w_750/h_500/c_scale'
        }
        return options + '/'
    }
}

export class CloudinaryObject {
    filename: string
    filetype: { video?: boolean; image?: boolean }
    subfolders: string[]
    options: CloudinaryOptions
    project?: Project
    constructor(filename: string, filetype: { video?: boolean; image?: boolean }, options: CloudinaryOptions, subfolders: string[], project?: Project) {
        this.filename = filename
        this.filetype = filetype
        this.options = options
        this.subfolders = subfolders
        this.project = project
    }

    buildLinkAF() {
        let returnString = ''
        if (this.filetype.image) {
            returnString += cloudinaryImgLink
        } else {
            returnString += cloudinaryVidLink
        }
        returnString += this.options.optionReturn(this.filetype)
        returnString += 'v1665689490/konrad/'
        if (this.project) {
            returnString += this.project + '/'
        } else {
            returnString += 'villaLeuna/'
        }
        let count = 0

        this.subfolders.forEach((x) => {
            returnString += x + '/'
            count++
        })
        if (this.subfolders.length === 0) {
            returnString += '/'
        }
        if (count === this.subfolders.length) {
            returnString += this.filename
            return returnString + '?=' + packageInfo.version
        }
    }
}
