import { getAvailableProjects } from './flat/flat_gen_Functions'

export enum Floors_Wrapper {
    HeadInfo = 0,
    Info,
    List,
    ImageLinks,
    Carousel,
    CenterTextSep,
    CenterTextSep_nonAvailable,
    InfoTextSep,
    BottomImage,
    Sep,
    SepSec,
}

export function getFloorsInfoWrapped(data: Floors_Wrapper) {
    switch (data) {
        case Floors_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Mietwohnungen', 'in der Villa Leuna'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wohnen in ruhiger Lage',
                            to: '/wohnungen',
                            slash: false,
                        },
                    ],
                },
                image: {
                    name: 'img_1',
                    folder: ['imgs'],
                    alt: 'Konzeptbild',
                },
                content: [
                    'Hochwertige und moderne Wohnungen in Leuna',
                    `Willkommen an der Villa Leuna. In ruhiger und grüner Wohnlage liegen hier zwei stilvolle Mehrfamilienhäuser mit insgesamt 12 Mietwohnungen. 
                    Die Wohnungen umfassen großzügige Grundrisse, hochwertige Ausstattung und eine moderne Architektur.`,
                ],
            }
        case Floors_Wrapper.CenterTextSep:
            return {
                content: [
                    `Erkunden Sie auch den Alten Fährhof`,
                    `Wir präsentieren Ihnen stolz 14 großzügige und hochwertige Wohnungen im Alten Fährhof in Bad Dürrenberg, direkt an der Saale. Genießen Sie die ruhige und grüne Lage direkt am Saaleufer.`,
                ],
                link: {
                    text: 'Zum Alten Fährhof',
                    link: 'http://alter-faehrhof.de',
                    scrollTo: false,
                },
            }
        case Floors_Wrapper.CenterTextSep_nonAvailable:
            return {
                content: [
                    `Es sind aktuell keine Mietwohnungen verfügbar.`,
                    ` Erkunden Sie auch unser neustes Projekt! Wir präsentieren Ihnen stolz 14 großzügige und hochwertige Wohnungen im Alten Fährhof in Bad Dürrenberg, direkt an der Saale. `,
                ],
                link: {
                    text: 'Zum Alten Fährhof',
                    link: 'http://alter-faehrhof.de',
                    scrollTo: false,
                },
            }
        case Floors_Wrapper.InfoTextSep:
            return {
                head: { title: 'Überblick der Ausstattung' },
                content: [
                    {
                        title: 'Wohnen',
                        rows: ['Fußbodenheizung', 'Echtholzparkett', 'Moderne und große Räume', 'Hochwertige Innenausstattung'],
                    },
                    {
                        title: 'Energie',
                        rows: [
                            'Effiziente Luft-Wasser-Wärmepumpe',
                            'Hochwertige Fenster mit Dreifachverglasung als Wärme- und Schallschutz',
                            'Niedrige Energiekosten durch hohe Dämmung (KfW-Effizienzhaus 70) ',
                            'Be- und Entlüftungsanlage mit Wärmerückgewinnung',
                        ],
                    },
                    {
                        title: 'Stellplätze',
                        rows: ['Stellplätze für Autos in Garagen', 'Abstellmöglichkeit für Fahrräder in Fahrradraum', 'Öffentliche Parkplätze direkt vor der Tür'],
                    },
                    {
                        title: 'Lage',
                        rows: [
                            'Villenlage in der Gartenstadt Leuna',
                            'Verkehrsberuhigte Lage mit kurzem Weg zum Saale-Ufer',
                            'Verkehrsgünstige Lage durch direkte Anbindung an die B91, mit schnellem Weg zur A9 und A38',
                        ],
                    },
                ],
            }
        case Floors_Wrapper.BottomImage:
            return {
                image: {
                    name: 'img_garagen',
                    folder: ['imgs'],
                    alt: 'Garagenhof',
                },
            }
        case Floors_Wrapper.ImageLinks:
            let flatAvailability = getAvailableProjects()
            return {
                head: { title: 'Überblick der Mietwohnungen' },
                content: [
                    {
                        id: 'johanna',
                        available: flatAvailability.includes('johanna'),
                        link: '/wohnungen/johanna',
                        title: 'Villa Johanna',
                        image: {
                            name: 'img_f_johanna',
                            folder: ['imgs'],
                            alt: 'Mietwohnungen in Villa Johanna',
                        },
                    },
                    {
                        id: 'justina',
                        available: flatAvailability.includes('justina'),
                        link: '/wohnungen/justina',
                        title: 'Villa Justina',
                        image: {
                            name: 'img_f_justina',
                            folder: ['imgs'],
                            alt: 'Mietwohnungen im Villa Justina',
                        },
                    },
                ],
            }
        case Floors_Wrapper.Carousel:
            const _images = []

            _images.push({
                name: 'img_1',
                folder: ['imgs'],
                alt: 'Mietwohnungen in Villa Leuna',
            })

            _images.push({
                name: 'img_3',
                folder: ['imgs', 'johanna'],
                alt: 'Mietwohnungen in Villa Johanna',
            })

            _images.push({
                name: 'img_eg',
                folder: ['imgs'],
                alt: 'Erdgeschoss Villa Johanna',
            })

            _images.push({
                name: 'img_1',
                folder: ['imgs', 'flats', 'we_1'],
                alt: 'Mietwohnungen in Villa Johanna',
            })
            _images.push({
                name: 'img_7',
                folder: ['imgs', 'flats', 'we_1'],
                alt: 'Mietwohnungen in Villa Johanna',
            })

            _images.push({
                name: 'img_4',
                folder: ['imgs', 'flats', 'we_1'],
                alt: 'Mietwohnungen in Villa Johanna',
            })
            _images.push({
                name: 'img_6',
                folder: ['imgs', 'flats', 'we_1'],
                alt: 'Mietwohnungen in Villa Johanna',
            })
            _images.push({
                name: 'img_5',
                folder: ['imgs', 'flats', 'we_1'],
                alt: 'Mietwohnungen in Villa Johanna',
            })
            _images.push({
                name: 'img_9',
                folder: ['imgs', 'flats', 'we_1'],
                alt: 'Mietwohnungen in Villa Johanna',
            })
            _images.push({
                name: 'img_11',
                folder: ['imgs', 'flats', 'we_1'],
                alt: 'Mietwohnungen in Villa Johanna',
            })

            return {
                head: {
                    title: ['Bilder?'],
                },
                images: _images,
            }
    }
}
