import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { splitPathname } from '../helpers/handlePathname'
import { useScrollBlock } from '../helpers/scrollHandler'
import React from 'react'

export default function NavigateTo(props) {
    const [route, setRoute] = useState(false)
    const { pathname } = useLocation()
    const [lastPath, setLastPath] = useState(false)

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [blockScroll, allowScroll] = useScrollBlock()
    useEffect(() => {
        allowScroll()
        const path = splitPathname(pathname)
        if (path === lastPath) return
        setLastPath(path)
        const johanna = [1, 2, 3, 4, 5, 6]
        const justina = [7, 8, 9, 10, 11, 12]
        if ((path.segment === 'johanna' || path.segment === 'justina') && path.number === 0) {
            setRoute('invalid')
        } else if (path.segment === 'justina') {
            if (path.number === -1) {
                setRoute(false)
            } else if (justina.includes(path.number)) {
                if (path.segment === 'wohnungen') {
                    setRoute('/wohnungen/justina/' + path.number)
                }
            } else if (johanna.includes(path.number)) {
                setRoute('/wohnungen/johanna/' + path.number)
            } else {
                setRoute('invalid')
            }
        } else if (path.segment === 'johanna') {
            if (path.number === -1) {
                setRoute(false)
            } else if (johanna.includes(path.number)) {
                if (path.segment === 'wohnungen') {
                    setRoute('/wohnungen/johanna/' + path.number)
                }
            } else if (justina.includes(path.number)) {
                setRoute('/wohnungen/justina/' + path.number)
            } else {
                setRoute('invalid')
            }
        } else {
            setRoute(false);
        }
    }, [props, pathname])
    if (route === 'invalid') {
        return <>{<Navigate to={'/'} />}</>
    } else {
        return <>{route !== false && <Navigate to={route} />}</>
    }
}
