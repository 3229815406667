import { flatData } from '../flat/flats_data'

export function getFlatAvailabilities(): boolean[] {
    return [
        /* Flat 1 */ false,
        /* Flat 2 */ true,
        /* Flat 3 */ false,
        /* Flat 4 */ false,
        /* Flat 5 */ false,
        /* Flat 6 */ true,
        /* Flat 7 */ false,
        /* Flat 8 */ false,
        /* Flat 9 */ false,
        /* Flat 10 */ false,
        /* Flat 11 */ false,
        /* Flat 12 */ false,
    ]
}

export function checkFlatAvailability(flatNumber?: number): boolean {
    const flats = getFlatAvailabilities()
    if (flatNumber) {
        if (flatNumber >= 1 && flatNumber < flats.length + 1) {
            console.log(flatNumber + ' is available:' + flats[flatNumber - 1])
            return flats[flatNumber - 1]
        } else {
            throw new Error('Invalid flat number')
        }
    } else {
        for (let i = 0; i < flats.length; i++) {
            if (flats[i]) {
                return true
            }
        }
        return false
    }
}

export function getAvailableProjects(): string[] {
    const projects: string[] = []
    const addedProjects: Set<string> = new Set()
    const flats = getFlatAvailabilities()

    for (let i = 0; i < flats.length; i++) {
        if (flats[i]) {
            const flatNumber = i
            const project = flatData[flatNumber].segment
            if (!addedProjects.has(project)) {
                projects.push(project)
                addedProjects.add(project)
            }
        }
    }

    return projects
}
