import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const TabSEO = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
        const empty = 'Villa Leuna'
        let title = empty
        switch (location.pathname) {
            case '/':
                title = empty
                break
            case '/about':
                title += ' - Kontakt'
                break
            case '/impressum':
                title += ' - Impressum'
                break
            case '/datenschutz':
                title += ' - Datenschutz'
                break
            case `/lage`:
                title += ' - Umgebung & Lage'
                break
            case `/projekte`:
                title += ' - Weitere Projekte'
                break
            case `/wohnungen`:
                title += ' - Mietwohnungen'
                break
            case location.pathname.match('/wohnungen/johanna/[0/-6]')?.input:
                title += ' - Villa Johanna ' + location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
                break
            case location.pathname.match('/wohnungen/justina/[7/-12]')?.input:
                title += ' - Villa Justina ' + location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
                break
            case `/wohnungen/johanna`:
                title += ' - Villa Johanna'
                break
            case `/wohnungen/justina`:
                title += ' - Villa Justina'
                break
            default:
                title = empty
        }

        document.title = title
    }, [location.pathname])
    return children
}
