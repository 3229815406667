import { Project } from '../cloudinaryHelper'

export enum OtherProjects_Wrapper {
    HeadInfo = 0,
    Descrb,
    BottomText,
    BottomImage,
    CenterImage,
}

export function getOtherProjectsInfoWrapped(data: OtherProjects_Wrapper) {
    switch (data) {
        case OtherProjects_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Weitere Projekte'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wir sind mehr als nur die Villa Leuna',
                            to: '/projekte',
                            slash: false,
                        },
                    ],
                },
                images: [
                    {
                        description: '',
                        name: 'main_5',
                        folder: ['imgs', 'root'],
                        alt: 'Alter Fährhof - 1',
                        project: Project.alterfaehrhof,
                    },
                    {
                        description: '',
                        name: 'main_2',
                        folder: ['imgs', 'root'],
                        alt: 'Alter Fährhof - 2',
                        project: Project.alterfaehrhof,
                    },
                    {
                        description: '',
                        name: 'terraced_3',
                        folder: ['imgs', 'root'],
                        alt: 'Alter Fährhof - 3',
                        project: Project.alterfaehrhof,
                    },
                    {
                        description: '',
                        name: 'img_13_flur_og',
                        folder: ['imgs', 'flats', 'flat_6'],
                        alt: 'Alter Fährhof - 4',
                        project: Project.alterfaehrhof,
                    },
                    {
                        description: '',
                        name: 'img_9_bad',
                        folder: ['imgs', 'flats', 'flat_10'],
                        alt: 'Alter Fährhof - 5',
                        project: Project.alterfaehrhof,
                    },
                ],
                content: [
                    'Willkommen im Alten Fährhof',

                    `Nur einen Katzensprung von Leuna entfernt erwartet Sie der Alte Fährhof, ein frisch kernsanierter Wohnkomplex mit insgesamt 14 traumhaften Mietwohnungen direkt an der Saale. 
                    Die hochwertigen Wohnungen bieten eine Wohnfläche zwischen 35 m² und 140 m². Lassen Sie sich von unserem stilvollen Ambiente am Saaleufer und der hochwertigen Ausstattung begeistern.`,
                ],
                links: [
                    {
                        text: 'Erkunde den Alten Fährhof',
                        link: 'https://alter-faehrhof.de/',
                        target: true,
                        scrollTo: false,
                    },
                ],
            }

        case OtherProjects_Wrapper.Descrb:
            return {
                images: [
                    {
                        description: '',
                        name: 'new',
                        folder: ['imgs'],
                        alt: 'Villa Arbor - 1',
                        project: Project.villaArbor,
                    },

                    {
                        description: '',
                        name: 'wohnzimmer',
                        folder: ['imgs'],
                        alt: 'Dachgeschoss',
                        project: Project.villaArbor,
                    },
                    {
                        description: '',
                        name: 'bath_1',
                        folder: ['imgs'],
                        alt: 'Villa Arbor - Bad',
                        project: Project.villaArbor,
                    },
                    {
                        description: '',
                        name: 'bath_2',
                        folder: ['imgs'],
                        alt: 'Villa Arbor - Bad',
                        project: Project.villaArbor,
                    },
                    {
                        description: '',
                        name: 'flur',
                        folder: ['imgs'],
                        alt: 'Dachgeschoss',
                        project: Project.villaArbor,
                    },
                ],
                content: [
                    'Erkunde die Villa Arbor',

                    `Erleben Sie wunderschönes Wohnen in einem hochwertig sanierten Wohngebäude inmitten einer ruhigen Wohnlage in Leuna. 
                    Die Villa Arbor bietet großzügige und helle Wohnräume.
                    Die Wohnungen erstrecken sich über eine Fläche von 64 m² bis 130 m² und bieten somit ausreichend Platz für ein komfortables und hochwertiges Wohngefühl. 
                    Hier finden Sie den perfekten Ort, um zur Ruhe zu kommen und Ihren Alltag hinter sich zu lassen. 
                    Entspannen Sie auf Ihrem Balkon, Terrasse oder in großzügigen Wohnzimmer und genießen Sie die angenehme Atmosphäre.`,
                ],
                links: [
                    {
                        text: 'Hin zur Villa Arbor',
                        link: 'https://www.villa-arbor.de/',
                        target: true,
                        scrollTo: false,
                    },
                ],
            }

        case OtherProjects_Wrapper.BottomText:
            return {
                content: ['XXXXXX'],
            }
        case OtherProjects_Wrapper.CenterImage:
            return {
                image: {
                    name: 'img_water',
                    folder: ['imgs'],
                    alt: 'Saale - 1',
                },
            }
        case OtherProjects_Wrapper.BottomImage:
            return {
                image: {
                    name: 'img_water_2',
                    folder: ['imgs'],
                    alt: 'Saale - 2',
                },
            }
    }
}
