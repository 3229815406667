/* eslint-disable no-case-declarations */
/* eslint-disable no-lone-blocks */
import { Image } from '../../server'
import { flatData } from '../flat/flats_data'
import { Flat_Wrapper, Flat_Wrapper_Levels } from '../flat/flat_class_Wrapped'
import { checkFlatAvailability } from '../flat/flat_gen_Functions'

/* In progress */
export enum Johanna_Wrapper {
    HeadInfo = 0,
    Descrb,
    TextSep,
    SthElse,
    Carousel,
    BottomSthElse,
    FlatList,
}

export function getVillaJohannaInfoWrapped(data: Johanna_Wrapper) {
    switch (data) {
        case Johanna_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Villa Johanna'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wohnungen zum verlieben',
                            to: '/wohnungen/johanna',
                            slash: false,
                        },
                    ],
                    subTitle: ['Modern und hochwertig <br/>Wohnen in der Villa Johanna'],
                },
                image: {
                    name: 'img_f_johanna',
                    folder: ['imgs'],
                    alt: 'Villa Johanna',
                },
                content: {
                    banner: ['ab 96m²', 'bis 109m²'],
                    text: [
                        `Willkommen in der Villa Johanna. ` +
                            `Die Mietwohnungen im Neubau Villa Johanna bieten durchdacht gestalteten, hochwertigen Wohnraum, der Funktionalität und Ästhetik harmonisch vereint. ` +
                            `Mit einem modernen Schnitt und qualitativ hochwertiger Ausstattung, inklusive effizienter Be- und Entlüftungsanlagen, gewährleisten diese Einheiten ein behagliches und gesundes Wohnklima. `,
                    ],
                },
            }
        case Johanna_Wrapper.Descrb:
            return {
                image: {
                    name: 'img_4',
                    folder: ['imgs', 'johanna'],
                    alt: 'Konzeptbild',
                },
                content: [
                    'Wohnen in der Villa Johanna',
                    '- Ein exklusives Wohngefühl',
                    `Im stilvollen Treppenhaus, das von einem bequemen Aufzug ergänzt wird, erlebt man die gelungene Verbindung von Design und Praktikabilität. ` +
                        `In den sechs individuell gestalteten Wohnungen sorgen hochwertiges Echtholzparkett und erstklassige Badausstattungen für eine einladende Atmosphäre, die höchsten Wohnansprüchen gerecht wird. ` +
                        `Helle Räume und eine moderne Luft-Wasser-Wärmepumpe stehen für die perfekte Kombination von ansprechendem Wohnambiente und energieeffizienter Ausstattung in der Villa Johanna.`,
                ],
            }
        case Johanna_Wrapper.TextSep:
            return {
                content: [
                    'Die Villa Leuna, positioniert in der malerischen Villenlage von Leuna, vereint erhabenes Wohnen mit direkter Naturanbindung. Nur ein kurzer Weg trennt Sie von der Saale und ihren einladenden Spazierwegen, die zu erholsamen Auszeiten am Flussufer verführen.',
                ],
                link: { text: 'Erkunde die Lage', link: '', scrollTo: false },
            }

        case Johanna_Wrapper.Carousel:
            const _images = []
            const list = 8

            for (let i = 1; i < list; i++) {
                _images.push({
                    name: `img_${i}`,
                    folder: ['imgs', 'johanna'],
                    alt: 'Villa Johanna',
                })
            }

            return {
                head: {
                    title: ['Bilder?'],
                },
                images: _images,
            }
        case Johanna_Wrapper.BottomSthElse:
            return {
                title: ['Weitere Wohnungen'],
                links: [
                    {
                        text: 'Erkunde die Villa Justina ',
                        link: '/wohnungen/justina',
                        scrollTo: false,
                    },
                ],
                image: {
                    name: 'img_f_justina',
                    folder: ['imgs'],
                    alt: 'Villa Justina von Vorne',
                },
            }
        case Johanna_Wrapper.FlatList:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_EGFloor: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_OGFloor: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_DGFloor: any[] = []
            // eslint-disable-next-line no-lone-blocks
            {
                for (let k = 0; k < 6; k++) {
                    const data: Flat_Wrapper = flatData[k]
                    const newFlat: {
                        number: number
                        price: { main: number; partial: number }
                        size: number
                        available: boolean
                        rooms: { living: number; baths: number; sleep: number }
                        levels: { wLevels: Flat_Wrapper_Levels[]; nMain: number }
                        entrance: string
                        outSideExtra: string
                        blueprint: Image[]
                    } = {
                        number: data.flatNumber,
                        price: data.price,
                        size: data.size,
                        available: checkFlatAvailability(data.flatNumber),
                        rooms: data.rooms,
                        levels: data.levels,
                        entrance: data.entrance,
                        outSideExtra: data.outSideExtra,
                        blueprint: data.blueprint,
                    }

                    if (newFlat.levels.wLevels[0] === Flat_Wrapper_Levels.Erdgeschoss) {
                        _flats_EGFloor.push(newFlat)
                    } else if (newFlat.levels.wLevels[0] === Flat_Wrapper_Levels.Obergeschoss) {
                        _flats_OGFloor.push(newFlat)
                    } else {
                        _flats_DGFloor.push(newFlat)
                    }
                }
            }

            return {
                content: [
                    'Wohneinheiten',
                    'in der Villa Johanna',
                    'Im Haupthaus erwarten Sie insgesamt sieben charmante Wohneinheiten, die sich auf zwei Wohnungen pro Etage verteilen. ',
                    `Durch das zentrale Treppenhaus gelangen Sie bequem zu den einzelnen Wohnungen. 
                    Entdecken Sie die Vielfalt der Wohnungen und finden Sie Ihren ganz persönlichen Rückzugsort direkt an der Saale.`,
                ],
                image: {
                    name: 'img_eg',
                    folder: ['imgs'],
                    alt: 'Villa Johanna von Vorne',
                },
                mainLink: '/wohnungen/johanna',
                type: 'johanna',
                flats: [_flats_EGFloor, _flats_OGFloor, _flats_DGFloor],
                unavailable: {
                    projectNone: {
                        content: [
                            `Es sind aktuell keine Mietwohnungen verfügbar. `,
                            'Erkunden Sie auch unser neustes Projekt! Wir präsentieren Ihnen stolz 14 großzügige und hochwertige Wohnungen im Alten Fährhof in Bad Dürrenberg, direkt an der Saale.',
                        ],
                        link: {
                            text: 'Zum Alten Fährhof',
                            link: 'http://alter-faehrhof.de',
                            scrollTo: false,
                        },
                    },
                    projectOther: {
                        content: [`Es sind aktuell keine Mietwohnungen in der Villa Johanna verfügbar.`, 'Erkunden Sie auch die Villa Justina nach freien Wohnungen.'],
                        link: {
                            text: 'Zur Villa Justina',
                            link: '/wohnungen/justina',
                            scrollTo: false,
                        },
                    },
                },
            }
    }
}
