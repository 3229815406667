export enum Location_Wrapper {
    HeadInfo = 0,
    Descrb,
    BottomText,
    BottomImage,
    CenterImage,
}

export function getLocationInfoWrapped(data: Location_Wrapper) {
    switch (data) {
        case Location_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Lage'],
                    titleSlash: true,
                    subTitle: ['Im direkten Umland von Halle und Leipzig'],
                    links: [
                        {
                            text: `Traumhaftes Wohnen in Villenlage`,
                            to: '/lage',
                            slash: false,
                        },
                    ],
                },
                image: {
                    name: 'img_water_3',
                    folder: ['imgs'],
                    alt: 'Zweimen_Der_Teich',
                    source: {
                        text: 'Dguendel',
                        link: 'https://commons.wikimedia.org/wiki/File:Zweimen_%28Leuna%29,_der_Teich.jpg',
                        additional: {
                            text: 'CC BY 3.0',
                            link: 'https://creativecommons.org/licenses/by/3.0',
                        },
                    },
                },
                content: [
                    `Genießen Sie das Beste aus zwei Welten - die Stadtnähe und die idyllische Umgebung von Leuna.`,
                    `Traumhaftes Wohnen – Mitten in der grünen Villenidylle. ` +
                        `Tauchen Sie ein in eine Oase der Erholung und grenzenloser Freizeitmöglichkeiten, während Sie zugleich nur 30 Minuten von den pulsierenden Großstädten entfernt sind. ` +
                        `Hier erwartet Sie ein harmonisches Zusammenspiel aus Entspannung und perfekt angebundener Lage.`,
                ],
            }

        case Location_Wrapper.Descrb:
            return {
                image: {
                    name: 'img_leuna_kultur',
                    folder: ['imgs'],
                    alt: 'Konzeptbild',

                    source: {
                        text: 'Wikswat',
                        link: 'https://commons.wikimedia.org/wiki/File:Leuna_Kulturhaus_2017.JPG',
                        additional: {
                            text: 'CC BY-SA 3.0 DEED',
                            link: 'https://creativecommons.org/licenses/by-sa/3.0/deed',
                        },
                    },
                },
                content: [
                    'Über die Stadt Leuna',
                    `Leuna verknüpft lebendige Industriegeschichte mit charmanten Naturkulissen. ` +
                        `In der eleganten Villenlage erwartet Sie ein idyllischer Spaziergang an der Saale in nur wenigen Gehminuten Entfernung. ` +
                        `Die beeindruckende Industriearchitektur trifft hier auf entspannte Grünflächen, die Raum für Erholung und Aktivitäten bieten. ` +
                        'Entdecken Sie Leuna – eine Stadt, in der Dynamik und Ruhe aufeinandertreffen. ',
                ],
                links: [
                    {
                        text: 'Erkunde den Saaleradweg',
                        link: 'https://www.saaleradweg.de/etappen/etappe-7/',
                        scrollTo: false,
                        target: true,
                    },
                ],
            }

        case Location_Wrapper.BottomText:
            return {
                content: [
                    'Anbindung an das Umland',
                    `Leuna, im Herzen Sachsen-Anhalts, bietet hervorragende Verkehrsanbindungen. In kürzester Zeit gelangen Sie nach Halle und Leipzig. ` +
                        `Die städtische Infrastruktur mit einem gut organisierten öffentlichen Nahverkehr erleichtert zudem das Erkunden von Leuna selbst und seiner reizvollen Umgebung. ` +
                        `Entdecken Sie mit Leuna als Startpunkt die kulturelle und historische Vielfalt der Umgebung. `,
                ],
            }
        case Location_Wrapper.CenterImage:
            return {
                image: {
                    name: 'img_leuna_aerial',
                    folder: ['imgs'],
                    alt: 'Leuna_wüsteneutzsch_Aerial',

                    source: {
                        text: 'PaulT (Gunther Tschuch)',
                        link: 'https://commons.wikimedia.org/wiki/File:Bad_D%C3%BCrrenberg,_das_Gradierwerk-5.jpg',
                        additional: {
                            text: 'CC BY-SA 4.0 DEED',
                            link: 'https://creativecommons.org/licenses/by-sa/4.0/',
                        },
                    },
                },
            }
        case Location_Wrapper.BottomImage:
            return {
                image: {
                    name: 'img_leuna_center',
                    folder: ['imgs'],
                    alt: 'Leuna_Zentrum',
                    source: {
                        text: 'Joeb07',
                        link: 'https://commons.wikimedia.org/wiki/File:Leuna_Haupttorplatz.jpg#filelinks',
                        additional: {
                            text: 'CC BY 3.0',
                            link: 'https://creativecommons.org/licenses/by/3.0',
                        },
                    },
                },
            }
    }
}
